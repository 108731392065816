import { useMemo } from "react";

import { Button } from "@new-black/lyra";

import {
  useFBSignInWithGithub,
  useFBSignInWithGoogle,
  useFBSignInWithMicrosoft,
} from "providers/firebase";

export const SignIn = () => {
  const [signInWithGithub, , githubLoading] = useFBSignInWithGithub();
  const [signInWithGoogle, , googleLoading] = useFBSignInWithGoogle();
  const [signInWithMicrosoft, , microsoftLoading] = useFBSignInWithMicrosoft();

  const isLoading = useMemo(
    () => githubLoading || googleLoading || microsoftLoading,
    [githubLoading, googleLoading, microsoftLoading],
  );

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-4">
        <Button
          variant="secondary"
          fullWidth
          isLoading={googleLoading}
          isDisabled={isLoading}
          onPress={() => signInWithGoogle()}
        >
          Google
        </Button>
        <Button
          variant="secondary"
          fullWidth
          isLoading={microsoftLoading}
          isDisabled={isLoading}
          onPress={() => signInWithMicrosoft()}
        >
          Microsoft
        </Button>
        <Button
          variant="secondary"
          fullWidth
          isLoading={githubLoading}
          isDisabled={isLoading}
          onPress={() => signInWithGithub()}
        >
          Github
        </Button>
      </div>
    </div>
  );
};
