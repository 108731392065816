import { useIntl } from "react-intl";

import { Card, CardContent, CardHeader } from "@new-black/lyra";

import { ErrorFallback } from "components/shared/error-fallback";
import { LogoIcon } from "components/shared/header/logo";
import LoadingStateBox from "components/shared/loading-state-box";
import { Page } from "components/shared/page";
import PageCenter from "components/shared/page-center";
import { TitleHelmet } from "components/suite-composite/title-helmet";
import UIErrorBoundary from "components/suite-ui/error-boundary";
import { useGetFBAuth } from "providers/firebase";

import { CustomerEndpointSelect } from "./customer-endpoint-select";
import { SignIn } from "./sign-in";

export function Component() {
  const intl = useIntl();
  const [user, loading] = useGetFBAuth();
  return (
    <>
      <TitleHelmet
        title={intl.formatMessage({
          id: "select-endpoint.title",
          defaultMessage: "Select endpoint",
        })}
      />
      <Page>
        <PageCenter>
          <div className="w-[360px] max-w-full">
            <div className="flex justify-center pb-10 pt-6">
              <LogoIcon className="text-primary" width={121} height={46} />
            </div>
            <Card>
              <CardHeader
                title={intl.formatMessage({
                  id: "select-endpoint.title",
                  defaultMessage: "Select endpoint",
                })}
              />
              <UIErrorBoundary>
                {loading ? (
                  <LoadingStateBox limit={3} headerAvailable />
                ) : (
                  <CardContent>
                    {user ? (
                      <div className="w-full">
                        <CustomerEndpointSelect />
                      </div>
                    ) : (
                      <div className="w-full">
                        <SignIn />
                      </div>
                    )}
                  </CardContent>
                )}
              </UIErrorBoundary>
            </Card>
          </div>
        </PageCenter>
      </Page>
    </>
  );
}

Component.displayName = "SelectEndpoint";

export function ErrorBoundary() {
  return <ErrorFallback fullPage />;
}
