import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Button, FieldGroup, SearchListField, Select } from "@new-black/lyra";
import { helpers, state } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { getEnv, useGetCustomers } from "providers/firebase";
import routeDefinitions from "routes/route-definitions";

const { storage } = helpers;

type EnvironmentOption = {
  name: string;
};

export const CustomerEndpointSelect = () => {
  const intl = useIntl();
  const setEndpointState = useSetRecoilState(state.core.evaEndpointUrlState);
  const navigate = useNavigate();

  const [results] = useGetCustomers();

  const customers = useMemo(() => {
    console.log("[CUSTOMER-ENDPOINT-SELECT] results", results);
    return results
      ?.map((result) => ({
        name: result.name,
        id: result.name,
        environments: Object.values(result.targetEnvironments),
      }))
      ?.filter((result) => result?.name && result?.environments?.length)
      ?.sort((a, b) => a.name.localeCompare(b.name));
  }, [results]);

  const [selectedCustomer, setSelectedCustomer] = useState<any>(undefined);

  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentOption | undefined>(
    undefined,
  );

  const [endpoint, setEndpoint] = useState<string | undefined>(undefined);

  const environmentOptions = useMemo<EnvironmentOption[]>(
    () =>
      selectedCustomer?.environments
        ?.map((env: any) => ({
          name: env.name,
        }))
        .sort((a: EnvironmentOption, b: EnvironmentOption) => a.name.localeCompare(b.name)),
    [selectedCustomer],
  );

  useEffect(() => {
    if (selectedEnvironment) {
      const baseEnv = selectedCustomer?.environments.find(
        (env: any) => env.name === selectedEnvironment.name,
      )?.baseEnvironmentReference;

      if (baseEnv) {
        (async () => {
          const res = await getEnv(baseEnv);
          const data = res.data();
          setEndpoint(data?.endpoint);
        })();
      }
    }
  }, [selectedEnvironment, selectedCustomer?.environments]);

  const updateEndpoint = useCallback(() => {
    if (endpoint) {
      setEndpointState(endpoint);
      storage.setItem("evaEndpointUrl", endpoint);
      navigate(routeDefinitions.auth.login.path, {
        replace: true,
        state: { endpointSelected: true },
      });
    }
  }, [endpoint, setEndpointState, navigate]);

  return (
    <>
      <FieldGroup>
        <SearchListField
          label={intl.formatMessage({ id: "generic.label.customer", defaultMessage: "Customer" })}
          items={customers ?? []}
          getItemId={(item) => item.id}
          getLabel={(item) => item.name}
          isRequired
          selectRenderElements={(item) => ({ label: item.name })}
          value={selectedCustomer}
          onChange={(newValue) => setSelectedCustomer(newValue)}
          isDisabled={!!selectedEnvironment}
          // TODO: remove when we fixed disabling the clear button when isDisabled is true
          disableClearLogic={!!selectedEnvironment}
        />
        <Select
          name="environments"
          label={intl.formatMessage({
            id: "generic.label.environment",
            defaultMessage: "Environment",
          })}
          items={environmentOptions ?? []}
          value={selectedEnvironment}
          onChange={(newValue) => {
            setSelectedEnvironment(newValue);
          }}
          isRequired
          className="disabled:!bg-surface-secondary disabled:!text-tertiary"
          isDisabled={!selectedCustomer}
          getLabel={(item) => item.name}
          getItemId={(item) => item.name}
          selectRenderElements={(item) => ({ label: item.name })}
        />
      </FieldGroup>
      <Button className="mt-4" fullWidth isDisabled={!endpoint} onPress={updateEndpoint}>
        <FormattedMessage id="select-endpoint.button.select" defaultMessage="Select Endpoint" />
      </Button>
    </>
  );
};
