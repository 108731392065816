import {
  useAuthState,
  useSignInWithGithub,
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from "react-firebase-hooks/auth";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  initializeFirestore,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD51D-mGBu-wAOxckCZO2-dk5IRjrYhNlI",
  authDomain: "eva-customer-manager.firebaseapp.com",
  databaseURL: "https://eva-customer-manager.firebaseio.com",
  projectId: "eva-customer-manager",
  storageBucket: "eva-customer-manager.appspot.com",
  messagingSenderId: "59729598142",
  appId: "1:59729598142:web:cdd4b538c72630c6dd70ef",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {
  // https://n6k.atlassian.net/browse/OPTR-26776
  // Use long polling does seem to fix the issue reported
  experimentalAutoDetectLongPolling: true,
});

const useFBSignInWithGoogle = () => {
  return useSignInWithGoogle(auth);
};

const useFBSignInWithMicrosoft = () => {
  return useSignInWithMicrosoft(auth);
};

const useFBSignInWithGithub = () => {
  return useSignInWithGithub(auth);
};
const useGetFBAuth = () => {
  return useAuthState(auth);
};

const firebaseAuth = {
  useGetFBAuth,
  useFBSignInWithGithub,
  useFBSignInWithGoogle,
  useFBSignInWithMicrosoft,
};

const useGetCustomers = () => {
  return useCollectionDataOnce(collection(db, "customer"));
};

const getEnv = async (document: DocumentReference) => {
  const docRef = doc(db, "baseEnvironment", document.id);
  const docRes = await getDoc(docRef);
  return docRes;
};

export {
  auth,
  db,
  firebaseAuth,
  useFBSignInWithGithub,
  useFBSignInWithGoogle,
  useFBSignInWithMicrosoft,
  useGetFBAuth,
  useGetCustomers,
  getEnv,
};
